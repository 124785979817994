import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardFooter, CardTitle, Col, Form, FormFeedback, Input, Label, Row, Table } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import { getAreas as onGetAreas, resetSaveAreaSuccess as onResetSaveAreaSuccess } from "../../../store/Config/actions"
import { AreaRowTr } from "./AreaRow";
import FormAreaModal from "./FormAreaModal";

const CatAreas = props => {

    const dispatch = useDispatch()

    const { areas, period, saveAreaSuccess } = useSelector(state => ({
        areas: state.ConfigInit.areas,
        period: state.ConfigInit.period,
        saveAreaSuccess: state.ConfigInit.saveAreaSuccess
    }));

    const [area,setArea] = useState(null)
    const [modal, setModal] = useState(false);

    const toggle = (area=null) => {
        const modalL = !modal
        setModal(modalL);
        console.log(area)
        if(modalL) {
            setArea(area)
            onResetSaveAreaSuccess(false)
        } else {
            setArea(null)
      }
    }


    useEffect(()=>{
        if(saveAreaSuccess){
            setModal(false);
            setArea(null)
        }
    },[saveAreaSuccess])

    return (
        <>
            <Card>
                  <CardTitle>Configuración del Municipio</CardTitle>
                  <CardBody>

                    <Row>
                        <Col lg={12} className="mb-3 text-end">
                            <Button onClick={toggle} type="button" color="primary">Crear Área</Button>
                        </Col>
                    </Row>
                    <Table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Clave</th>
                                <th>Titular</th>
                                <th>Cargo</th>
                                <th>Área</th>
                                <th>Status</th>
                                <th>Opciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                areas.map((item,key)=>{return (<AreaRowTr key={key} item={item} toggle={toggle} />)})
                            }
                        </tbody>
                    </Table>

                  </CardBody>
            </Card>

            <FormAreaModal isOpen={modal} toggle={toggle} area={area} />
        </>
    )
}

export default CatAreas