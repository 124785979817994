export const GET_DOCUMENT = "GET_DOCUMENT";
export const GET_DOCUMENT_ALL = "GET_DOCUMENT_ALL";
export const GET_DOCUMENT_ALL_SUCCESS = "GET_DOCUMENT_ALL_SUCCESS";

export const GET_REQUISITON_LIST = "GET_REQUISITON_LIST";
export const GET_REQUISITON_LIST_SUCCESS = "GET_REQUISITON_LIST_SUCCESS";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const SET_REQUISITION = "SET_REQUISITION";
export const SAVE_REQUISITION = "SAVE_REQUISITION";
export const SET_TOTAL_ITEMS = "SET_TOTAL_ITEMS";

export const getDocument = filter => ({
    type: GET_DOCUMENT,
    payload: filter
});

export const getDocumentAll = filter => ({
    type: GET_DOCUMENT_ALL,
    payload: filter
});
export const getDocumentAllSuccess = data => ({
    type: GET_DOCUMENT_ALL_SUCCESS,
    payload: data
});

export const getRequisitionList = filter => ({
    type: GET_REQUISITON_LIST,
    payload: filter
});

export const getRequisitionListSuccess = data => ({
    type: GET_REQUISITON_LIST_SUCCESS,
    payload: data
});

export const setActiveTab = step => ({
    type: SET_ACTIVE_TAB,
    payload: step
});

export const setRequisition = req => ({
    type: SET_REQUISITION,
    payload: req
});

export const saveRequisition = data => ({
    type: SAVE_REQUISITION,
    payload: data
});

export const setTotalItems = total => ({
    type: SET_TOTAL_ITEMS,
    payload: total
});
