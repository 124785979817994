import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap"

import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { saveArea as onSaveArea, updateArea as onUpdateArea } from "../../../store/Config/actions";

const FormAreaModal = props => {
  const { isOpen, toggle, area } = props

  const dispatch = useDispatch()

  const [statusChecked,setStatusChecked] = useState(false)

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: area?.id ? area?.id : null,
      name: area?.name ? area?.name : null,
      titular: area?.titular ? area?.titular : null,
      position: area?.position ? area?.position : null,
      codeKey: area?.code_key ? area?.code_key : null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Por favor agrega  nombre"),
      titular: Yup.string().required("Por favor agrega el nombre de titular"),
      position: Yup.string().required("Por favor agrega el cargo"),
      codeKey: Yup.string().required("Por favor agrega  la nomenclatura"),
    }),
    onSubmit: (values) => {
      if(values["id"]){
        values["active"] = statusChecked
        dispatch(onUpdateArea(values))
      } else {
        dispatch(onSaveArea(values))
      }
    },
  });


  useEffect(()=> {
    return () => {
      validation.resetForm()
    }
  },[]);

  useEffect(()=> {
    setStatusChecked(area?.active)
  },[area]);

  return (
    <Modal
      isOpen={isOpen}
      role="dialog" size="lg"
      autoFocus={true}
      centered={true}
      className="exampleModal"
      tabIndex="-1"
      toggle={toggle}
    >
      <div className="modal-content">
        <Form>
        <ModalHeader toggle={toggle}>{ area?.id ? "Editar Área" : "Crear Área"}</ModalHeader>
        <ModalBody>
        <Row className="mb-4">
                      <Label
                        htmlFor="name"
                        className="col-sm-3 col-form-label"
                      >
                          Titular
                      </Label>
                      <Col sm={9}>
                      <Input
                                    type="text" name="titular" id="titular"
                                    className="form-control" placeholder=""
                                    onChange={validation.handleChange} 
                                    onBlur={validation.handleBlur}
                                    value={validation.values.titular || ""}
                                    invalid={
                                        validation.touched.titular && validation.errors.titular ? true : false
                                    }
                                />
                                {validation.touched.titular && validation.errors.titular ? (
                                    <FormFeedback type="invalid">{validation.errors.titular}</FormFeedback>
                                ) : null}
                      </Col>
          </Row>
          <Row className="mb-4">
                      <Label
                        htmlFor="name"
                        className="col-sm-3 col-form-label"
                      >
                          Cargo
                      </Label>
                      <Col sm={9}>
                      <Input
                                    type="text" name="position" id="position"
                                    className="form-control" placeholder=""
                                    onChange={validation.handleChange} 
                                    onBlur={validation.handleBlur}
                                    value={validation.values.position || ""}
                                    invalid={
                                        validation.touched.position && validation.errors.position ? true : false
                                    }
                                />
                                {validation.touched.position && validation.errors.position ? (
                                    <FormFeedback type="invalid">{validation.errors.position}</FormFeedback>
                                ) : null}
                      </Col>
          </Row>
          <Row className="mb-4">
                      <Label
                        htmlFor="name"
                        className="col-sm-3 col-form-label"
                      >
                          Nombre del Área
                      </Label>
                      <Col sm={9}>
                      <Input
                                    type="text" name="name" id="name"
                                    className="form-control" placeholder=""
                                    onChange={validation.handleChange} 
                                    onBlur={validation.handleBlur}
                                    value={validation.values.name || ""}
                                    invalid={
                                        validation.touched.name && validation.errors.name ? true : false
                                    }
                                />
                                {validation.touched.name && validation.errors.name ? (
                                    <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                                ) : null}
                      </Col>
          </Row>
          <Row className="mb-4">
          <Label
                        htmlFor="codeKey"
                        className="col-sm-3 col-form-label"
                      >
                          Nomenclatura del Área
                      </Label>
                      <Col sm={4}>
                      <Input
                                    type="text" name="codeKey" id="codeKey"
                                    className="form-control" placeholder=""
                                    onChange={validation.handleChange} 
                                    onBlur={validation.handleBlur}
                                    value={validation.values.codeKey || ""}
                                    invalid={
                                        validation.touched.codeKey && validation.errors.codeKey ? true : false
                                    }
                                />
                                {validation.touched.codeKey && validation.errors.codeKey ? (
                                    <FormFeedback type="invalid">{validation.errors.codeKey}</FormFeedback>
                                ) : null}
                      </Col>
          </Row>
          {
            area?.id ? 
            <Row className="mb-4">
                      <Label
                        htmlFor="name"
                        className="col-sm-3 col-form-label"
                      >
                          Status
                      </Label>
                      <Col sm={9}>
                      <div className="form-check mb-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="areaStatus"
                            id="areaStatusActive"
                            value="active"
                            checked={statusChecked}
                            onClick={(e) => setStatusChecked(1)}

                          />
                          <label
                            className="form-check-label"
                            htmlFor="areaStatusActive"
                          >
                            Activo
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="areaStatus"
                            id="areaStatusInactive"
                            value="inactive" 
                            checked={!statusChecked} 
                            onClick={(e) => setStatusChecked(0)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="areaStatusInactive"  
                          >
                            Baja
                          </label>
                        </div>
                      </Col>
          </Row>:""

          }
          
        
            
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="secondary" onClick={toggle}>
            Cancelar
          </Button>
          <Button onClick={(e)=>{
            e.preventDefault();
            console.info(validation.errors)
            validation.handleSubmit();
          }} type="submit" color="primary">
            Guardar
          </Button>
        </ModalFooter>
        </Form>
      </div>
    </Modal>
  )
}

FormAreaModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
}

export default FormAreaModal
