import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap"

import classnames from "classnames"
import { useDispatch, useSelector } from "react-redux"
import FormStep1 from "./FormStep1"
import { Link } from "react-router-dom"
import FormStep2 from "./FormStep2"
import FormStep3 from "./FormStep3"
import FormStep4 from "./FormStep4"
import { useFormik } from "formik"
import * as Yup from "yup"

import {
  getAreas as onGetAreas,
  getCats as onGetCats,
  getConfigInit as onGetConfigInit
} from "../../store/Config/actions"
import {
  saveRequisition as onSaveRequisition,
  setActiveTab as onSetActiveTab,
  setRequisition as onSetRequisition
} from "store/actions"

const FormWizardReq = props => {

  const { invoicesAdd, isOpen, pagination } = props

  const dispatch = useDispatch()

    const { areas } = useSelector(state => ({
      areas: state.ConfigInit.areas,
    }));

    const  { activeTab, requisition, comissionFinance, sourceFinancingList, passedSteps } = useSelector(state => ({
        activeTab: state.Requisitions.activeTab,
        requisition: state.Requisitions.requisition,
        comissionFinance: state.ConfigInit.comissionFinance,
        sourceFinancingList: state.ConfigInit.sourceFinancingList,
        passedSteps: state.Requisitions.passedSteps
    }));

    //const [activeTab, setactiveTab] = useState(1)
    const [invoicesList, setInvoicesList] = useState([])

    const valForm1 = useFormik({
        enableReinitialize: true,
        initialValues: {
          codeKey: requisition!==null ? requisition?.form1?.codeKey:"",
            dateReq: requisition!==null ? requisition?.form1?.dateReq:"",
            subject: requisition!==null ? requisition?.form1?.subject:"",
            applicant: requisition!==null ? requisition?.form1?.applicant:"",
            position: requisition!==null ? requisition?.form1?.position:"",
            area: requisition!==null ? requisition?.form1?.area:"",
            description: requisition!==null ? requisition?.form1?.description:"",
        },
        validationSchema: Yup.object({
            dateReq: Yup.string().required("Ingresa la fecha"),
            subject: Yup.string().required("Ingresa el asunto"),
            applicant: Yup.string().required("Ingresa el nombre"),
            position: Yup.string().required("Ingresa el puesto"),
            area: Yup.string().required("Ingresa el area"),
            description: Yup.string().required("Ingresa el objeto de la solicitud"),
            
        }),
        onSubmit: (values) => {
          let requiNew = [];
          if(requisition) {
            requiNew = requisition;
          } 
          requiNew['page'] = pagination?.page?pagination?.page:1
          requiNew['pageSize'] = pagination?.pageSize?pagination?.pageSize:10
          requiNew['step_current'] = requisition && requisition?.step_current ? requisition?.step_current:1
          requiNew['status'] = requisition && requisition?.status ? requisition?.status : "Incompleto"
          const newReq = [];
            if(activeTab===1) {
              requiNew["form1"]= values;
              requiNew["form1"]["invoicesAdd"]=invoicesList;
              requiNew["form1"]["applicantObj"] = areas.find(item=> item.id=== parseInt(values["applicant"]));
              dispatch(onSaveRequisition(requiNew))
            }
            /*if(newReq?.id && reqEditable) {
                   
            } else {
                    //dispatch(onSaveProdocut(newProduct));
                    //handlePruductEditable();
                    toggleTab(activeTab + 1)
            }*/
        },
    });

    const valForm2 = useFormik({
      enableReinitialize: true,

      initialValues: {
        codeKey: comissionFinance!==null ? comissionFinance?.finance?.code_key:"",
        dateReq: requisition!==null ? requisition?.form2?.dateReq:"",
        subject: "OFICIO DE AUTORIZACIÓN DE SOLICITUD", //requisition!==null ? requisition?.form2?.subject:"",
        description: requisition!==null ? requisition?.form2?.description:"",
      },
      validationSchema: Yup.object({
        dateReq: Yup.string().required("Ingresa la fecha"),
          //subject: Yup.string().required("Ingresa el asunto"),
          description: Yup.string().required("Ingresa el objeto de la solicitud"),
          
      }),
      onSubmit: (values) => {
        const requiNew = requisition
        requiNew['step_current'] = 2
        requiNew['status'] = requisition && requisition?.status ? requisition?.status : "Incompleto"
        const newReq = [];
        requiNew['page'] = pagination?.page?pagination?.page:1
        requiNew['pageSize'] = pagination?.pageSize?pagination?.pageSize:10

        requiNew["form2"]= values;
        requiNew["form2"]["invoicesAdd"]=invoicesList;
        console.info("newReq", requiNew)
        dispatch(onSaveRequisition(requiNew))
        
      },
  });

  const valForm3 = useFormik({
    enableReinitialize: true,

    initialValues: {
      codeKey: comissionFinance!==null ? comissionFinance?.president?.code_key:"",
      dateReq: requisition!==null ? requisition?.form3?.dateReq:"",
      subject:  "AUTORIZACIÓN DE GASTO", //requisition!==null ? requisition?.form3?.subject:"",
      description: requisition!==null ? requisition?.form2?.description:"",
    },
    validationSchema: Yup.object({
      dateReq: Yup.string().required("Ingresa la fecha"),
        //subject: Yup.string().required("Ingresa el asunto"),
        //description: Yup.string().required("Ingresa el objeto de la solicitud"),
        
    }),
    onSubmit: (values) => {
      const newReq = [];
      const requiNew = requisition
      requiNew['step_current'] = 3
      requiNew['page'] = pagination?.page?pagination?.page:1
      requiNew['pageSize'] = pagination?.pageSize?pagination?.pageSize:10

      requiNew['status'] = requisition && requisition?.status ? requisition?.status : "Incompleto"
      requiNew["form3"]= values;
      console.info("newReq", requiNew)
      dispatch(onSaveRequisition(requiNew))
      
    },
  });

  const valForm4 = useFormik({
    enableReinitialize: true,

    initialValues: {
      //codeKey: comissionFinance!==null ? comissionFinance?.president?.code_key:"",
      dateReq: requisition!==null ? requisition?.form4?.dateReq:"",
      subject: "SOLICITUD DE PAGO", //requisition!==null ? requisition?.form4?.subject:"SOLICITUD DE PAGO",
      wayPay: requisition!==null ? requisition?.form4?.wayPay:"",
      sourceFinancing: requisition!==null ? requisition?.form4?.sourceFinancing:"",
      textIntro1: (requisition!==null && requisition?.form1?.invoicesAdd?.length>0)?(requisition?.form1?.invoicesAdd?.length>1)?" las facturas ":" la factura ":requisition?.form4?.textIntro1,
      textIntro2: (requisition!==null && requisition?.form1?.invoicesAdd?.length>0)?" del proveedor ":requisition?.form4?.textIntro2,
      amountTotal: (requisition!==null && requisition?.form1?.invoicesAdd?.length>0)? "-":requisition?.form4?.amountTotal,
      noCheck: (requisition!==null && requisition?.form4?.waPay!=="2")? "-":requisition?.form4?.noCheck,
    },
    validationSchema: Yup.object({
      dateReq: Yup.string().required("Ingresa la fecha"),
      wayPay: Yup.string().required("Elige la forma de pago"),
      sourceFinancing: Yup.string().required("Elige la fuente de financiamiento"),
      textIntro1: Yup.string().required("Agrega el texto obligatorio"),
      textIntro2: Yup.string().required("Agrega el texto obligatorio"),
      amountTotal:Yup.string().required("Ingresa el total a pagar"),
      noCheck:Yup.string().required("Ingresa el número de cheque"),
    }),
    onSubmit: (values) => {
      const newReq = [];
      const requiNew = requisition
      requiNew['step_current'] = 4
      requiNew['page'] = pagination?.page?pagination?.page:1
      requiNew['pageSize'] = pagination?.pageSize?pagination?.pageSize:10

      requiNew['status'] = "Completo"
      requiNew["form4"]= values;
      dispatch(onSaveRequisition(requiNew))
    },
  });
  
    
    function toggleTab(tab) {
      let activeTabL = tab
      
      if (activeTab !== activeTabL) {
        var modifiedSteps = [...passedSteps, activeTabL]
        if (activeTabL >= 1 && activeTabL <= 4) {
          dispatch(onSetActiveTab({activeTab:activeTabL,passedSteps:modifiedSteps}))
        }
      }
    }

    const handleChangeInvoiceItem = (e,target, key2,key1) => {
      const invoicesL = [...invoicesList]
      const invL = invoicesL[key1];
      invL.det[key2][e.target.name] = e.target.value;
      invoicesL.splice(key1,1,invL);
      setInvoicesList(invoicesL)
    }

    useEffect(()=> {
       dispatch(onGetAreas({active:true}))
       dispatch(onGetConfigInit())
       dispatch(onGetCats())
    },[]);

    useEffect(()=>{
      if(invoicesAdd.length>0) {
        setInvoicesList(invoicesAdd)
      }
    },[])

  useEffect(()=> {
    if(!isOpen) {
      valForm1.resetForm()
      valForm2.resetForm()
      valForm3.resetForm()
      valForm4.resetForm()
      dispatch(onSetRequisition(null))
      dispatch(onSetActiveTab({activeTab:1,passedSteps:[1]}))
    } else {
      if(requisition && requisition?.step_current>1) {
        if(requisition?.step_current<4){ 
          const modifiedSteps = [...(passedSteps), requisition?.step_current + 1]
          dispatch(onSetActiveTab({activeTab:requisition?.step_current+1,passedSteps:modifiedSteps}))
        } else {
          const modifiedSteps = [...(passedSteps), 4]
          dispatch(onSetActiveTab({activeTab:4,passedSteps:modifiedSteps}))
        }
        if(requisition?.step_current>=2) {
          setInvoicesList((requisition.form2.invoicesAdd))
        } else if (requisition.step_current===1) {
          setInvoicesList((requisition.form1.invoicesAdd))
        }
      }
    }
  },[isOpen]);

    return (<>
        <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="wizard clearfix">
                    <div className="steps clearfix">
                      <ul>
                        <NavItem
                          className={classnames({ current: activeTab === 1 })}
                        >
                          <NavLink
                            className={classnames({ current: activeTab === 1 })}
                            onClick={() => {
                              onSetActiveTab(1)
                            }}
                            disabled={!(passedSteps || []).includes(1)}
                          >
                            <span className="number">1.</span> Solicitud 
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 2 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 2 })}
                            onClick={() => {
                              onSetActiveTab(2)
                            }}
                            disabled={!(passedSteps || []).includes(2)}
                          >
                            <span className="number">2.</span> Autorización de Solicitud
                            
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 3 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 3 })}
                            onClick={() => {
                              onSetActiveTab(3)
                            }}
                            disabled={!(passedSteps || []).includes(3)}
                          >
                            <span className="number">3.</span> Autorización del Gasto
                          </NavLink>
                        </NavItem>
                        <NavItem
                          className={classnames({ current: activeTab === 4 })}
                        >
                          <NavLink
                            className={classnames({ active: activeTab === 4 })}
                            onClick={() => {
                              onSetActiveTab(4)
                            }}
                            disabled={!(passedSteps || []).includes(4)}
                          >
                            <span className="number">4.</span> Solicitud de Pago
                          </NavLink>
                        </NavItem>
                      </ul>
                    </div>
                    <div className="content clearfix">
                      <TabContent activeTab={activeTab} className="body">
                        <TabPane tabId={1}>
                            <FormStep1 catAreas={areas} validation={valForm1}/>
                        </TabPane>
                        <TabPane tabId={2}>
                          <div>
                            <FormStep2  catAreas={areas} invoicesList={invoicesList} handleChangeInvoiceItem={handleChangeInvoiceItem} validation={valForm2} />
                          </div>
                        </TabPane>
                        <TabPane tabId={3} >
                          <div>
                            <FormStep3 catAreas={areas} validation={valForm3} />
                          </div>
                        </TabPane>
                        <TabPane tabId={4}>
                          <div>
                            <FormStep4 sourceFinancingList={sourceFinancingList} validation={valForm4}/>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                    <div className="actions clearfix">
                      <ul>
                        <li
                          className={
                            activeTab === 1 ? "previous disabled" : "previous"
                          }
                        >
                          <Link
                            to="#"
                            onClick={() => {
                              toggleTab(activeTab - 1)
                            }}
                          >
                            Previous
                          </Link>
                        </li>
                        <li
                          className={activeTab === 4 ? "next btn-success" : "next"}
                        >
                          <Link
                            to="#"
                            onClick={() => {
                                console.info(activeTab)
                                if(activeTab===1) {
                                    valForm1.handleSubmit();
                                } else if (activeTab===2) {
                                  valForm2.handleSubmit();
                                } else if (activeTab===3) {
                                  valForm3.handleSubmit();
                                } else {
                                  valForm4.handleSubmit();
                                    //toggleTab(activeTab + 1)
                                }
                              //toggleTab(activeTab + 1)
                            }}
                          >
                            { activeTab === 4 ? "Finalizar": "Guardar y Siguiente" }
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
        </Row>
    </>
    )
}


export default FormWizardReq