import React from 'react';
import { Badge, ButtonGroup } from 'reactstrap';


export const AreaRowTr = ({item, toggle}) => {

    const toggleLocal = (item) => {
        toggle(item)
    }

    return (
        <tr>
            <td>{item?.code_key}</td>
            <td>{item?.titular}</td>
            <td>{item?.position}</td>
            <td>{item?.name}</td>
            <td><Badge  style={{padding:5}} color={item?.active?"success":"danger"} >{item?.active?"Activo":"Baja"}</Badge></td>
            <td>
                <ButtonGroup>
                <button href='#' className='btn btn-warning' onClick={(e)=>{e.preventDefault();toggleLocal(item)}}>
                    <i className="fa fa-pencil"></i>Editar
                </button>
               
                </ButtonGroup>
            </td>
        </tr>
    );
};
