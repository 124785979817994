import {
    SET_ACTIVE_TAB,
    GET_REQUISITON_LIST_SUCCESS,
    SET_REQUISITION, GET_DOCUMENT_ALL_SUCCESS, SET_TOTAL_ITEMS
} from "./actions"

const INIT_STATE = {
    activeTab: 1,
    requisitionList:[],
    requisition:null,
    passedSteps:[1],
    docUrl:null,
    totalItems:0
};

const Requisitions = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_REQUISITON_LIST_SUCCESS:
            return {
                ...state,
                requisitionList: action.payload.data.data,
                totalItems: action.payload.data.total,
              }
        case GET_DOCUMENT_ALL_SUCCESS:
            return {
                ...state,
                docUrl: action.payload,
            }
        case SET_ACTIVE_TAB:
            
            return {
                ...state,
                activeTab: action.payload.activeTab,
                passedSteps: action.payload.passedSteps
              }
        case SET_REQUISITION:
            return {
                ...state,
                requisition: action.payload,
              }
        case SET_TOTAL_ITEMS:
            return {
                ...state,
                totalItems: action.payload
              }
        default:
            return state;
    }
}


export default Requisitions;